<template>
  <v-form>
    <v-container>
      <user-form
        v-model="user"
        :user-roles="userRoles"
        :entities="entities"
        :loading="loading"
        :errors="errors"
      />

      <v-row
        class="justify-end"
      >
        <v-btn
          class="grey mr-4"
          :loading="loading"
          @click="$emit('cancel')"
        >
          {{ $t('back') }}
        </v-btn>
        <v-btn
          class="primary mr-4"
          :loading="loading"
          @click="validate"
        >
          {{ $t('create') }}
        </v-btn>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
import UsersRepository from '@/api/UsersRepository'
import EntitiesRepository from '@/api/EntitiesRepository'
import UserForm from '@/views/dashboard/components/Users/UserForm'

export default {
  name: 'UserUpdate',
  components: { UserForm },
  props: {
    original: {
      type: Object,
      required: true,
    },
  },
  data () {
    return {
      user: {
        name: null,
        email: null,
        user_role_id: null,
        entity_id: null,
      },
      userRoles: [
        { id: 1, role: 'Admin' },
        { id: 2, role: 'Stock Manager' },
        { id: 3, role: 'Operator' },
        { id: 4, role: 'Entity Admin' },
      ],
      entities: [],
      errors: this.errorsStartState(),
      loading: true,

    }
  },
  computed: {
    body () {
      return {
        name: this.user.name,
        email: this.user.email,
        user_role_id: this.user.user_role_id,
        entity_id: this.user.entity_id,
      }
    },
  },
  watch: {
    user: {
      handler (value) {
        if (value.user_role_id !== this.$root.$data.rolesIds.entityAdmin) {
          value.entity_id = null
        }
      },
      deep: true,
    },
  },
  created () {
    EntitiesRepository.list()
      .then(({ data }) => {
        this.entities = data
        this.user = this.original
        this.loading = false
      })
  },
  methods: {
    validate () {
      this.errorsStartState()
      if (this.user.user_role_id === this.$root.$data.rolesIds.entityAdmin) {
        this.errors.entity_id = this.user.entity_id ? null : 'Must select the entity of the user'
      }
      if (!this.errors.entity_id) {
        this.updateUser()
      }
    },
    updateUser () {
      this.loading = true
      UsersRepository.update(this.original.id, this.body)
        .then(({ data }) => {
          this.$emit('userUpdated')
        })
        .catch(({ response }) => {
          this.loading = false
          if (response.status === 422) {
            const { data } = response
            Object.keys(data.errors).forEach(key => {
              this.errors[key] = data.errors[key]
            })
          }
          this.loading = false
        })
    },
    errorsStartState () {
      return {
        name: null,
        email: null,
        user_role_id: null,
        entity_id: null,
      }
    },
  },
  i18n: {
    messages: {
      it: {
        c: {
          entitySelectLabel: 'Entità in cui lavora l\'user',
        },
      },
      en: {
        c: {
          entitySelectLabel: 'Entity where user works',
        },
      },
    },
  },

}

</script>

<style scoped>

</style>

<style scoped>

</style>
