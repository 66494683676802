<template>
  <base-material-card
    icon="mdi-clipboard-text"
    class="px-5 py-3"
  >
    <v-data-table
      dense
      :headers="headers"
      :items="users"
      :loading="loading"
      item-key="id"
      class="pt-8"
      loading-text="Loading... Please wait"
      hide-default-footer
      disable-sort
      disable-pagination
    >
      <template v-slot:item.action="{ item }">
        <div class="my-2">
          <v-btn
            class="primary"
            small
            @click="updateUser = item"
          >
            {{ $t('edit') }}
          </v-btn>
        </div>
      </template>
    </v-data-table>
    <div class="text-center">
      <v-dialog
        v-if="updateUser"
        v-model="updateUser"
        width="600"
      >
        <v-card>
          <v-card-title>
            {{ $t('c.updateUser.title') }}
          </v-card-title>
          <v-card-text>
            <user-update
              :original="updateUser"
              @cancel="updateUser = null"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </div>
  </base-material-card>
</template>
<script>

import UserUpdate from '@/views/dashboard/pages/UsersIndex/components/UserUpdate'

export default {
  name: 'UsersList',
  components: { UserUpdate },
  props: {
    users: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      search: '',
      headers: [
        { text: this.$t('name'), value: 'name' },
        { text: this.$t('email'), value: 'email' },
        { text: this.$t('role'), value: 'role.slug' },
        { text: 'Actions', value: 'action', sortable: false },
      ],
      updateUser: null,
    }
  },
  methods: {
    show (id) {
      this.$router.push({
        name: 'user.show',
        params: {
          userId: id,
        },
      })
    },
  },
  i18n: {
    messages: {
      it: {
        c: {
          updateUser: {
            title: 'Modifica user',
          },
        },
      },
      en: {
        c: {
          updateUser: {
            title: 'Update User',
          },
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
