<template>
  <v-form>
    <v-container class="py-0">
      <v-row>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="value.name"
            :label="$t('name') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.name : null"
          />
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-text-field
            v-model="value.email"
            :label="$t('email') | capitalize"
            :disabled="disabled"
            :loading="loading"
            :error-messages="errors ? errors.email : null"
          />
        </v-col>

        <v-col
          cols="12"
        >
          <v-select
            v-model="value.user_role_id"
            :items="userRoles"
            item-text="role"
            item-value="id"
            :label="$t('userType') | capitalize"
            :error-messages="errors.user_role_id"
          />
        </v-col>

        <v-col
          cols="12"
        >
          <v-select
            v-if="value.user_role_id === $root.$data.rolesIds.entityAdmin"
            v-model="value.entity_id"
            :items="entities"
            item-text="name"
            item-value="id"
            :label="$t('entity') | capitalize"
            :loading="loading"
            :hint="$t('c.entitySelectLabel')"
            :error-messages="errors.entity_id"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<script>
export default {
  name: 'UserForm',
  props: {
    value: {
      type: Object,
      required: true,
    },
    userRoles: {
      type: Array,
      default: () => [],
    },
    entities: {
      type: Array,
      default: () => [],
    },
    errors: {
      type: Object,
      default: null,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  i18n: {
    messages: {
      it: {
        c: {
          entitySelectLabel: 'Entità in cui lavora l\'user',
        },
      },
      en: {
        c: {
          entitySelectLabel: 'Entity where user works',
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
