import Repository from './Repository'

const resource = '/api/users'

export default {
    index (params) {
        return Repository.get(`${resource}`, {
            params,
        })
    },
    show (id, params = {}) {
        return Repository.get(`${resource}/${id}`, {
            params,
        })
    },
    store (body) {
        return Repository.post(`${resource}`, body)
    },
    update (id, body) {
        return Repository.put(`${resource}/${id}`, body)
    },

}
