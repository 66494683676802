<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Users"
      link="components/simple-tables"
    />

    <v-row>
      <v-col
        cols="12"
        md="9"
      >
        <users-list
          :users="users"
          :loading="loading"
        />
      </v-col>

      <v-col
        cols="12"
        md="3"
      >
        <v-row>
          <v-col>
            <v-card>
              <v-card-title
                class="green darken-1 grey--text  text--lighten-4 pa-4 mb-4"
              >
                {{ $t('c.createUserCard.title') }}
              </v-card-title>
              <v-card-text>
                <div>
                  <div>
                    <p>
                      {{ $t('c.createUserCard.body') }}
                    </p>
                  </div>
                  <div>
                    <v-btn
                      class="primary"
                      small
                      @click="createUserDialog = true"
                    >
                      {{ $t('c.createUserCard.button') }}
                    </v-btn>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <div class="text-center">
        <v-dialog
          v-if="createUserDialog"
          v-model="createUserDialog"
          width="600"
        >
          <v-card>
            <v-card-title>
              {{ $t('c.creatingUserCard.title') }}
            </v-card-title>
            <v-card-text>
              <user-create
                @cancel="createUserDialog = false"
                @userCreated="handleUserCreated"
              />
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import UsersRepository from '@/api/UsersRepository'
import UsersList from '@/views/dashboard/lists/UsersList'
import UserCreate from '@/views/dashboard/pages/UsersIndex/components/UserCreate'
import { capitalize } from '@/helpers/helpers'

export default {
  name: 'UsersIndex',
  components: { UserCreate, UsersList },
  // components: {CreateUserForm},
  data () {
    return {
      users: [],
      search: '',
      headers: [
        { text: capitalize(this.$t('name')), value: 'name' },
        { text: capitalize(this.$t('email')), value: 'email' },
        { text: capitalize(this.$t('role')), value: 'role' },
        { text: capitalize(this.$t('actions')), value: 'action', sortable: false },
      ],
      createUserDialog: false,
      loading: true,
    }
  },
  created () {
    this.getUsers()
  },
  methods: {
    getUsers () {
      UsersRepository.index({
        search: this.search,
        with: 'entity,userRole',
      })
        .then(({ data }) => {
          this.users = data.data
          this.loading = false
        })
        .catch(() => {
        })
    },
    handleUserCreated () {
      this.loading = true
      this.createUserDialog = false
      this.getUsers()
    },
  },
  i18n: {
    messages: {
      it: {
        c: {
          createUserCard: {
            title: 'Creare un nuovo user',
            body: 'Registra admin, tecnico o proprietario.',
            button: 'Nuovo user',
          },
          creatingUserCard: {
            title: 'Nuovo User',
          },
        },
      },
      en: {
        c: {
          createUserCard: {
            title: 'Create user',
            body: 'Register admin,technician or proprietario.',
            button: 'New user',
          },
          creatingUserCard: {
            title: 'New User',
          },
        },
      },
    },
  },
}
</script>

<style scoped>

</style>
