import Repository from './Repository'

const resource = '/api/entities'

export default {
  index (params) {
    return Repository.get(`${resource}`, {
      params,
    })
  },
  list () {
    return Repository.get(`${resource}/list`)
  },
  show (id, params) {
    return Repository.get(`${resource}/${id}`, {
      params,
    })
  },
  store (body) {
    return Repository.post(`${resource}`, body)
  },
}
